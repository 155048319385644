.romac-patient-id-cell {
    padding-left: 5px !important;
    text-align: left !important;
}

.first-row-cell {
    padding: 4px !important;
}

.patients-table-cases-header {
    padding-left: 13px !important;
}