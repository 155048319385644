.single-training img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: calc(100vh - 100px);
    text-align: center;
    display: block;
}

.single-training video {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: calc(100vh - 100px);
    text-align: center;
    display: block;
}